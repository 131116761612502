<template>
	<div >
		<v-row class="mx-0">
			<v-col class="pt-3">
				<v-row class="mx-0" no-gutters>
					<v-col cols="auto" class="pr-3">
						<v-btn @click="focus = $refs.calendar.times.today" small class="rounded-lg" outlined>
							<div class="caption-1">{{ $tc('single_trad.Calendar.today', 1) }}</div>
						</v-btn>
					</v-col>
					<v-col>
						<v-menu bottom right rounded="lg">
							<template v-slot:activator="{ on }">
								<v-btn small outlined v-on="on" class="rounded-lg">
									<span class="caption-1">{{ typeToLabel[type] }}</span>
									<v-icon right>mdi-menu-down</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item @click="type = 'day'">
									<v-list-item-content class="caption-1">{{ $tc('single_trad.Calendar.day', 1) }}</v-list-item-content>
								</v-list-item>
								<v-list-item @click="type = 'week'">
									<v-list-item-content class="caption-1">{{ $tc('single_trad.Calendar.week', 1) }}</v-list-item-content>
								</v-list-item>
								<v-list-item @click="type = 'month'">
									<v-list-item-content class="caption-1">{{ $tc('single_trad.Calendar.month', 1) }}</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</v-col>

			<v-col class="pt-3">
				<v-row align="center" justify="center">
					<v-btn fab text x-small @click="prev">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<div v-if="$refs.calendar" class="sub-title-2 font-weight-medium">{{ calendarTitle || $refs.calendar.title }}</div>
					<v-btn fab text x-small @click="next">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
			</v-col>
			
			<v-col align="right" class="pt-3">
				<v-row align="center" justify="end" no-gutters>
					<v-col v-if="search" cols="auto" class="pr-2">
						<v-row align="center" no-gutters>
							<v-col style="min-width: 32px" cols="auto">
								<v-btn fab text x-small :color="itemsSorted.length < 2 || index_search === 0 ? 'cell': 'primary'" v-show="itemsSorted.length >= 2 && index_search !== 0"
									@click="index_search--; focus = itemsSorted[index_search].start"
								>
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="auto">
								<div class="sub-title-2 font-weight-medium">{{ $tc('single_trad.Calendar.search', itemsSorted.length, {value: search, nmb: itemsSorted.length}) }}</div>
							</v-col>
							<v-col align="left" style="min-width: 32px" cols="auto">
								<v-btn 
									fab text x-small
									:color="itemsSorted.length < 2 || index_search === (itemsSorted.length - 1) ? 'cell': 'primary'"
									v-show="itemsSorted.length >= 2 && index_search !== itemsSorted.length - 1"
									@click="index_search++; focus = itemsSorted[index_search].start"
								>
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="auto">
						<v-menu bottom right rounded="lg" v-if="eventTypes.length > 1">
							<template v-slot:activator="{ on }">
								<v-btn small outlined v-on="on" class="rounded-lg">
									<span class="caption-1">{{ eventSelectedType.display_name }}</span>
									<v-icon right>mdi-menu-down</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item v-for="(t, i) in eventTypes" :key="i" @click="eventSelectedType = t">
									<v-list-item-content class="paragraph">{{ t.display_name }}</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
				
			</v-col>
		</v-row>
		
		<v-row v-if="events.length === 0 && ready" justify="center"> 
			<v-col class="title-2 py-6" cols="auto">{{ $tc('single_trad.Calendar.no_events', 1) }}</v-col>
		</v-row>

		<v-sheet :height="height" :width="width ? width : ''">	
			<v-calendar
				ref="calendar"
				v-if="itemsSorted"
				v-model="focus"
				class="fill-height fill-width rounded-b-lg overflow-hidden"
				color="primary"
				interval-count="12"
				interval-minutes="120"
				:events="itemsSorted"
				:event-color="getEventColor"
				:event-overlap-mode="(e, f, t) => columnsSetUp(e, f, t)"
				:event-overlap-threshold="45"
				:type="type"
				:locale="$store.getters['profile/user_language'].locale"
				:weekdays="weekdays"
				@click:event="showEvent"
				@click:more="viewDay"
				@click:date="viewDay"
			>
			</v-calendar>
			
			<!-- <Dialog v-if="selectedEvent" v-model="selectedOpen" :width="500" :title="$t('single_trad.Calendar.dialog_title') " @close="selectedOpen = false; readOnlySelectedEvent = true">
				<template v-slot:actions>
					<v-spacer></v-spacer>
					<v-btn small dark v-if="selectedEvent.__meta__.methods.find(e => e === 'retrieve')" icon @click="$router.push('/dashboard/' + path + '/'+ selectedEvent.id)" class="mr-2">
						<v-icon size="18">mdi-magnify-plus</v-icon>
					</v-btn>
					<v-btn small dark v-if="selectedEvent.__meta__.methods.includes('update')" icon @click="readOnlySelectedEvent = !readOnlySelectedEvent" class="mr-2">
						<v-icon size="18">mdi-pencil</v-icon>
					</v-btn>
					<v-btn small dark v-if="selectedEvent.__meta__.methods.includes('destroy')" icon @click="confirm_dialog = true" class="mr-2">
						<v-icon size="18">mdi-delete</v-icon>
					</v-btn>
				</template>
				<template v-slot:content>
					<FieldGroup
							class="pa-3"
							v-model="selectedEvent"
							:mode="readOnlySelectedEvent ? 'readonly': 'modification'"
							:fields="fields"
							:pevents="events"
							:path="path"
							:pathId="selectedEvent.id"
							@close="(e) => {fetchEntityItems();readOnlySelectedEvent = true}"
					/>
				</template>
			</Dialog> -->
		</v-sheet>

		<!-- <Dialog
                v-model="edit_dialog"
                :title="$t('single_trad.Dialog.fast_edit')"
                width="500"
                @close="edit_dialog = false"
        >
            <template v-slot:actions v-if="true">
                <Button iconVal="mdi-eye" icon dark :click="() => $router.push(path + '/' + item2edit.id)" />
                <Button iconVal="mdi-delete" icon dark :click="() => { item2delete = item2edit; confirm_dialog = true }" />
            </template>
            <template v-slot:content>
                <FieldGroup
                        class="px-3"
                        v-model="item2edit"
                        :fields="$tools.filterObject(fields, e => !e.read_only)"
                        :mode="'creation'"
                        :path="path"
                        :pevents="events"
                        :pathId="item2edit ? item2edit.id : ''"
                        @newItemCreated="(e) => events.notify('new-choice', e)"
                        @close="edit_dialog = false"
                        @created="(e) => { events.unshift(e); edit_dialog = false; $store.dispatch('snackbar/success', $tc('snackbar.FieldGroup.createAll.success', 1)) }"
                        @saved="$store.dispatch('snackbar/success', $tc('snackbar.EntityField.saveChanges.success', 1))"
                        @fail="$store.dispatch('snackbar/error', $tc('snackbar.FieldGroup.createAll.fail', 1))"
                        @failSaved="$store.dispatch('snackbar/error', $tc('snackbar.EntityField.saveChanges.fail', 1))"
                />
            </template>
        </Dialog> -->

		<!-- <Dialog
				v-model="confirm_dialog"
				type="confirm"
				:message=" $tc('single_trad.Table.dialog_v_delete', 1)"
				@cancel="confirm_dialog = false"
				@confirm="()=> deleteEvent()" :loading="loading"
		/> -->
	</div>
</template>
<script>
export default {
	name: "Calendar",
	props: {
		items: {},
		fields:{},
		path:{},
		actions:{},
		embed: {},
		height:{},
		width:{},
		routeChildren: {},
		methods: {},
		pevents: {}
	},
	components: {
		Dialog: () => import('@/components/ui/Dialog.vue'),
		FieldGroup: () => import("@/components/FieldGroup.vue"),
		Button: () => import('@/components/ui/Button.vue'),
        Actions: () => import('@/components/Actions.vue'),
	},
	data() {
		return {
			readOnlySelectedEvent: true,
			focus: "",
			type: "month",
			typeToLabel: {
				month: this.$tc('single_trad.Calendar.month', 1),
				week: this.$tc('single_trad.Calendar.week', 1),
				day: this.$tc('single_trad.Calendar.day', 1),
				today : this.$tc('single_trad.Calendar.today', 1)
			},
			selectedEvent: null,
			selectedOpen: false,
			colors: [
				'light-blue lighten-1',
				'blue',
				'indigo',
				'deep-purple',
				'purple',
				'pink',
				'red',
				'orange',
				'amber',
				'lime',
				'light-green',
				'green',
				'teal',
				'cyan'
			],
			names: [],
			itemsSorted: [],
			eventTypes: [],
			eventSelectedType: {id: -1, display_name: this.$tc('single_trad.Table.all')},
			loading: false,
			confirm_dialog: false,
			events: this.items || [],
			ready: false,
			item2edit: {},
			edit_dialog: false,
			search: null,
			index_search: 0
		}
	},
	computed: {
		weekdays(){
			if (this.$store.getters['profile/user_language'].locale !== 'en'){
				return [1, 2, 3, 4, 5, 6, 0]
			}
			else {
				return [0,1, 2, 3, 4, 5, 6]
			}
		},
		calendarTitle(){
			let date = this.$refs.calendar.value || this.$refs.calendar.start
			let type = this.$refs.calendar.type
			if (type === 'day')
				return this.$moment(date).format('DD MMM YYYY')
			if (type === '4day')
				return this.$moment(date).format('DD') + '-' + this.$moment(date).add(3, 'days').format('DD MMM YYYY')
			if (type === 'week')
				return this.$moment(date).weekday(0).format('DD') + '-' + this.$moment(date).weekday(6).format('DD MMM YYYY')
			if (type === 'month')
				return this.$moment(date).format('MMM YYYY')
        },

	},
	watch: {
		events(){
			/*this.translateDatesLocal()
			this.getTypes()
			this.getEventsByTypes()*/
		},
		eventSelectedType(){
			this.getEventsByTypes()
		}
	},
	methods: {
		viewDay({ date }) {
			this.focus = date;
			this.type = "day";
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			this.selectedEvent = Object.assign({}, event)
			this.selectedEvent['start'] = this.$options.filters.toUTCDate(event['start'])

			if(this.selectedEvent['end'] !== null && this.selectedEvent['end'] !== ""){
				this.selectedEvent['end'] = this.$options.filters.toUTCDate(event['end'])
			}
			else {
				this.selectedEvent['end'] = ""
			}

			this.selectedOpen = true
			this.pevents.notify('fast-edit', this.selectedEvent)
	
		},
		translateDatesLocal(){
			for(let key in this.fields){
                let field = this.fields[key]

				if(field.type === "datetime" || field.type === "date") {
					
					this.events.forEach((item, i) => {

						if (item[key] !== null && item[key] !== undefined){
							item[key] = this.$options.filters.toLocalDate(item[key])
						}
						if (!item.color){
							item.color = this.colors[i % this.colors.length]
						}
					})
				}
			}
		},
		fetchEntityItems() {
            this.$wsc.getList(this.path, {}, items => {
                this.events = items

				this.translateDatesLocal()
				this.getTypes()
				this.getEventsByTypes()
            }, fail => {
            })
        },
		getTypes(){
			this.eventTypes = []
			this.eventTypes.push({'display_name': this.$tc('single_trad.Table.all'), 'id': -1})
			
			this.events.forEach(item => {
				if (!this.eventTypes.find(t => t.id === item.type.id)){
					this.eventTypes.push(item.type)
				}
			})
		},
		getEventsByTypes(){
			let startItems = this.events.filter(item => item.start !== null)
			this.itemsSorted = []

			if (this.eventSelectedType.id === -1){
				this.itemsSorted = startItems
			}
			else {
				startItems.forEach(item => {
					if (item.type.display_name === this.eventSelectedType.display_name){
						this.itemsSorted.push(item)
					}
				})
			}
			this.ready = true
		},
		getEventColor (event) {
        	return event.color
      	},
		rnd (a, b) {
        	return Math.floor((b - a + 1) * Math.random()) + a
		},
		deleteEvent(){
			this.loading = true
			this.$wsc.deleteItem(this.path, this.selectedEvent.id, success => {
				let index = this.events.findIndex(e => e.id === success.id) 
				this.events.splice(index, 1)
				this.selectedOpen = false
				this.confirm_dialog = false
				this.loading= false
			}, fail => {
				this.loading= false
			})

		},
		columnsSetUp(events, firstWeekday, overlapThreshold) {
			return (day, dayEvents, timed) => {
				let events_day = []
				let type = this.$refs.calendar.type

				if (type === 'month')
					events_day = events.filter(e => e.start.date === day.date || (day.date > e.start.date && day.date <= e.end.date && this.$moment(day.date).weekday(0).format('YYYY-MM-DD') === day.date ) ).reverse()
				else
					events_day = events.filter(e => e.start.date <= day.date && e.end.date >= day.date ).reverse()
				let count = events_day.length
				return events_day.map((e,i) => {
					return {
						event: e,
						columnCount: count,
						left: i * (100/ count),
						width: 100 / count
					}
				})
			}
		},
		setUpHooks(){
			 this.pevents.wait('new-item', (item) => {
                    this.events.unshift(item)
					this.translateDatesLocal()
					this.getTypes()
					this.getEventsByTypes()
                })
			this.pevents.wait('saved-item', () => {
				if(this.selectedEvent) {
					let index = this.events.findIndex(e => e.id === this.selectedEvent.id)
					this.events.splice(index, 1, this.selectedEvent)
					this.selectedEvent = null
					this.translateDatesLocal()
					this.getTypes()
					this.getEventsByTypes()
				}
			})

			this.pevents.wait('delete-item', success => {
				let index = this.events.findIndex(e => e.id === parseInt(success.id))
				this.events.splice(index, 1)
			})

			this.pevents.wait('search', val => {
				this.search = val
				this.itemsSorted = this.events.filter(e => e.name.search(val) > -1)
				if (this.itemsSorted.length > 0)
					this.focus = this.itemsSorted[0].start
			})

			this.pevents.wait('search-clear', () => {
				this.search = null
				this.getTypes()
				this.getEventsByTypes()
			})
		}
	},
	created(){
		this.setUpHooks()
		if(typeof this.items === 'undefined'){
			this.fetchEntityItems()
		}
		else {
			this.getEventsByTypes()
			this.translateDatesLocal()
		}
		
	}
}
</script>
<style scoped>
.v-event-timed-container {

}
div.v-event-timed {
	width : 20% !important;
}
</style>